import { navigate } from 'gatsby'
import React from 'react'
import ErrorRunningSvg from 'images/error_running.svg'

interface Props {
  status: 400 | 404 | 500
}

export const ErrorPage: React.FC<Props> = ({ status }) => {
  const handleClick = () => {
    navigate('/login')
    return
  }

  return (
    <div data-test="errorPage" style={{ background: '#FFF' }}>
      <div
        style={{ width: '100%', height: '100%', paddingTop: '120px' }}
        className="flex flex-col items-center"
      >
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '48px',
            lineHeight: '70px',
            letterSpacing: '0.06em',
            color: 'rgba(7, 32, 100, 0.8)',
          }}
        >
          {status}
        </div>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '24px',
            lineHeight: '35px',
            letterSpacing: '0.06em',
            color: 'rgba(7, 32, 100, 0.8)',
          }}
        >
          エラーが発生したようです。
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '26px',
            letterSpacing: '0.06em',
            color: 'rgba(7, 32, 100, 0.4)',
            marginTop: '20px',
          }}
        >
          「トップに戻る」よりトップ画面に戻っていただくか、リロードしていただくと解消する場合があります。
        </div>
        <div style={{ marginTop: '40px' }}>
          <img src={ErrorRunningSvg}></img>
        </div>
        <div
          onClick={handleClick}
          className="flex items-center"
          style={{
            cursor: 'pointer',
            background: '#F6F6F6',
            borderRadius: '3px',
            padding: '15px 18px',
            marginTop: '50px',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              lineHeight: '156.5%',
              letterSpacing: '0.03em',
              color: '#828282',
              marginRight: '5px',
            }}
          >
            トップに戻る
          </span>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.00008 0L0.585083 1.415L5.17008 6L0.585083 10.585L2.00008 12L8.00008 6L2.00008 0Z"
              fill="#828282"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
